import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

export default function ReviewSlider({ data, slidesPerView }) {
  let sliderData = [];

  console.log('slider data', data);

  for (let i = 0; i < data.length; i++) {
    console.log(i);
    sliderData.push(
      <SwiperSlide key={i}>
            <div className='review-wrapper w-[95%] pt-12 pb-0 rounded-2xl bg-white'>
                <div className='message px-12 pb-12'>
                    {data[i].message}
                </div>
                <div className='details bg-[#F3F3F3] px-12 py-8 rounded-b-2xl flex items-center'>
                    <div className='image-wrapper w-16 h-16 rounded-[50%] bg-[#D9D9D9] overflow-clip mr-4'>
                        <img className='w-full h-full object-cover'src="/Assets/images/image-placeholder.png" alt={data[i].name}/>
                    </div>
                    <div className='person-detail'>
                        <span className='text-2xl text-black font-medium'>{data[i].name}</span><br/>
                        <span className='text-base text-purple-pri'>{ data[i].company}</span>
                    </div>
                </div>
            </div>
      </SwiperSlide>
    );
  }

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={slidesPerView}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        stopOnLastSlide: true,
      }} 
      pagination={true}
      className={'home-banner w-full !pb-24'}
      breakpoints={{
        0:{
          slidesPerView:1,
          spaceBetween:0
        },
        781:{
          slidesPerView:2,
          spaceBetween:10
        }
      }}
      modules={[EffectFade, Autoplay, Pagination, Navigation]}
    >
      {sliderData}
    </Swiper>
  );
}

export function ProjectCarousel({ data, carouselSwiperOption }) {
  let sliderData = [];

  if (data !== undefined) {
    for (let i = 0; i < data.length; i++) {
      sliderData.push(
        <SwiperSlide key={i}>
          { data[i] }
        </SwiperSlide>
      );
    }

    return (
      <Swiper
              {...carouselSwiperOption}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
              modules={[EffectFade, Autoplay, Pagination, Navigation]}
            >
              {sliderData}
      </Swiper>
    );
  } else {
    return <span>Currently we dont have any sale :</span>;
  }
}

// export function ProductCarousel({ data, currentColor, carouselSwiperOption }) {
//   return (
//     <div className="mobile">
//       {data.map((img1, i) => (
//         <Swiper
//           key={i}
//           id={img1.id}
//           {...carouselSwiperOption}
//           modules={[EffectFade, Autoplay, Pagination, Navigation]}
//         >
//           {currentColor === `${img1.id}` &&
//             img1.img.map((img2, j) => (
//               <SwiperSlide key={j}>
//                 <img
//                   src={
//                     'https://avamoony.com/onlineshop/img/Azalea%20Scarf/' + img2
//                   }
//                 />
//               </SwiperSlide>
//             ))}
//         </Swiper>
//       ))}
//     </div>
//   );
// }