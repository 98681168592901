import React from 'react';
import Navigation from './Navbar';
import Footer from './Footer';
const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};
export default Layout;
