import Header from "../../Components/All/Header";
import { Hero , Testimonial} from "../../Components/About";
import { StrongPointContainer, TeamMemberContainer, ContactUsCTA2, ClientContainer } from "../../Components/Home";

const About = () => {
return (
		<div className="aboutus-page">
			<Header title="About"></Header>

			<Hero />

			<StrongPointContainer />

			<TeamMemberContainer />

			<ContactUsCTA2 />

			<Testimonial />

			<ClientContainer />
			
		</div>
	);
}
export default About;