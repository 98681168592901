import { GoCheckCircle } from 'react-icons/go';

const ContactInfo = () => {
	const info = [
		{
			id: 0,
			title: 'Phone Number',
			info: ['(+60) 11 2331 3110', '(+60) 3 4270 6883'],
			image: '/Images/Contact/contactus-white.svg',
		},
		{
			id: 1,
			title: 'Email Address',
			info: ['contact', '@mxisolutions.com'],
			image: '/Images/Contact/email-white.svg',
		},
		{
			id: 2,
			title: 'Office Address',
			info: [
				'A-11-3A',
				'Jalan Selaman 1/1',
				'68000 Ampang',
				'Selangor, Malaysia',
			],
			image: '/Images/Contact/location-white.svg',
		},
	];
  
	return (
		<div className="section flex justify-center pb-20 sdesk1:pb-8 tracking-wider text-black px-10 tab1:px-3">
			<div className="w-full max-w-[1400px] main-border border-2 rounded-xl border-divide-border-l outline-2 flex flex-row justify-center">
				<div className="w-full grid divide-divide-border-l divide-x-2 outline-2 py-12 max:grid-cols-3 des:grid-cols-3 des:py-10 max1000:grid-cols-1 max1000:divide-x-0 max1000:gap-6">
					{info.map((item) => (
						<div key={item.id} className="wrapper flex gap-4 max:px-20 des:px-10 max1000:items-center max1000:pl-[4%] max1000:px-10">
							<div className="image mob:w-28 des:max-w-[3rem] des:min-w-[3rem] max:w-28">
								<img src={item.image} alt="item.title"/>
							</div>

							<div className="w-48">
								<div className="title pb-5">
									<span className="font-semibold text-2xl">{item.title}</span>
								</div>

								<div className="detail">
									{item.info.map((line, index) => (
										<p key={index} className="text-gray">
											{line}
										</p>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
  };

const ContactForm = () => {
	const benefit = [
		{ id: 0, title: 'Client-oriented' },
		{ id: 1, title: 'Client-oriented' },
		{ id: 2, title: 'Client-oriented' },
		{ id: 3, title: 'Client-oriented' },
		{ id: 4, title: 'Client-oriented' },
		{ id: 5, title: 'Client-oriented' },
	];
  
	return (
		<div className="section flex justify-center max:mb-44 sdesk1:mb-32 tab1:mb-20 tracking-wider text-black px-10 tab1:px-3">
			<div className="max-w-[1400px] py-20 px-16 rounded-lg bg-gray-bg-1 flex gap-10 tab1:!flex-col tab1:!px-6 tab1:!py-9 tab1:!gap-9">
				<div className="w-1/2 tab1:w-full">
					<div>
						<span className="header-black">
							We'd love to hear from you.
						</span>
					</div>
		
					<div className="py-11 tab1:py-4">
						<p className='paragraph-gray'>
							At our IT solution company, we are committed to providing
							exceptional customer service and support. If you are experiencing
							technical difficulties or need assistance with one of our
							services,
						</p>
					</div>
		
					<div>
						<div className="pb-6 font-semibold mob:text-base des:text-lg max:text-2xl">
							<span>Your benefits:</span>
						</div>
			
						<div className="flex flex-wrap gap-x-6 gap-y-4 des:gap-y-2">
							{benefit.map((item) => (
								<span className="flex gap-5 items-center">
									<GoCheckCircle size="26" color="#5B62FF" /> <span className='paragraph-gray'>{item.title}</span>
								</span>
							))}
						</div>
					</div>
				</div>
		
				<div className="w-1/2 tab1:w-full">
					<form className='h-full'>
						<div className="w-full flex gap-5 pb-6">
							<input
								className="input-short"
								placeholder="Name*"
								required
							></input>
			
							<input
								className="input-short"
								placeholder="Email*"
								required
							></input>
						</div>
			
						<div>
							<input className="input-long" placeholder="Website"></input>
						</div>

						<div className="py-6 h-[calc(100%-232px)] sdesk1:h-[calc(100%-232px)]">
							<textarea
								className="input-textarea"
								placeholder="Your Comment*"
								required
							></textarea>
						</div>

						<div>
							<button
								type="submit"
								className="w-full bg-[#5B62FF] py-5 rounded-lg text-white font-medium tracking-wider hover:bg-purple-bg-1"
							>
								Post Comment
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
  

export { ContactInfo, ContactForm };
  