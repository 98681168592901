import { Banner1,
	ServiceContainer,
	AboutUsContainer,
	ContactUsCTA,
	ContactUsCTA2,
	StrongPointContainer,
	CustomerFeedbackContainer,
	TeamMemberContainer,
	ProjectContainer,
	LocalAdsContainer,
	BlogsContainer,
	ClientContainer
} from '../../Components/Home'

const Home = () => {
    return(
        <div className='home-page page-container'>
            <Banner1 />
            <ServiceContainer />
            <AboutUsContainer />
            <ContactUsCTA />
            <ContactUsCTA2 />
            <StrongPointContainer />
            <CustomerFeedbackContainer />
            <TeamMemberContainer />
            <ProjectContainer />
            <LocalAdsContainer />
            <BlogsContainer />
            <ClientContainer />
        </div>
    )
};

export default Home;