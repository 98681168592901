const Header = ({ title, path }) => {
  return (
    <div className="page-header section w-full flex flex-col items-center tracking-widest gap-8 des:gap-6 mob:gap-5 pt-36 tab1:pt-9 sdesk1:pt-20 pb-24 tab1:pb-6 sdesk1:pb-12">
      <div className="header flex items-center">
        <span className="font-bold text-7xl tab1:!text-4xl sdesk1:text-6xl">{title}</span>
      </div>

      <div className="breadcrumb text-black max:text-xl mob:text-base des:text-md">
        <span>MXI Solutions &gt; {path} {title}</span>
      </div>
    </div>
  );
};
export default Header;
