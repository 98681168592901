import Header from '../../Components/All/Header';
import { ContactInfo, ContactForm } from '../../Components/Contact';

const Contact = () => {
  return (
    <div>
      <Header title="Contact"></Header>
      <ContactInfo></ContactInfo>
      <ContactForm></ContactForm>
    </div>
  );
};
export default Contact;
