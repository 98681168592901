import Header from "../../Components/All/Header";
import { BlogContent, BlogAuthor, BlogComment, BlogMenu } from "../../Components/Blog";

const BlogDetail = () => {
	return (
		<div>
			<Header title="Blogs > IT Solution And Business"/>

			<div className="flex justify-center pb-20">
				<div className="flex flex-wrap max-w-[1400px] w-full px-9">
					<div className="w-2/3 sdesk1:!w-full flex flex-col gap-6 sdesk1:mt-20 pr-6 sdesk1:pr-0">
						<BlogContent/>

						<BlogAuthor/>

						<BlogComment/>
					</div>


					<BlogMenu/>
				</div>
			</div>
		</div>
	);
}
export default BlogDetail;