import Header from "../../Components/All/Header";
import { FaPhoneAlt } from "react-icons/fa"
import { TbMailFilled } from "react-icons/tb"
import { FaLocationDot } from "react-icons/fa6"
import { RiUserSettingsLine } from "react-icons/ri"

import './ProjectDetail.css';

const PortfolioDetail = () => {
    const image = "/Assets/images/image-placeholder.png"

    const portfolio = {
        image: "/Images/About/abouthero.png",
        category: "Technology",
        name: "Mobile Application Design",
        service: "",
        client: "",
        date: {
            start: "",
            end: "",
        },
        body: [
			{
				type: 'text',
				content: {
					header: 'IT Solution And Business',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				}
			},
			{
				type: 'text',
				content: {
					header: 'The challenge of the project',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.'
				}
			},
			{
				type: 'image',
				image: ['/Images/About/abouthero.png', '/Images/About/abouthero.png', '/Images/About/abouthero.png', '/Images/About/abouthero.png']
			},
			{
				type: 'paragraph',
				content: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.'
			}
		],
    }

    return (
        <div className="project-container"> 
            <Header title="Web Development"/>

            <div className="content-container">
                <div className="max-w-[1400px]">
                    <div className="projectDetail-banner">
                        <img src={image} alt={portfolio.name} className="object-contain w-full"/>
                    </div>
                    
                    <div className="projectTitle-container">
                        <div className="category">
                            <span>Technology</span>
                        </div>

                        <div className="projectTitle">
                            <span className="">Mobile Application Design</span>
                        </div>
                    </div>
                    
                    <div className="projectDescription-container">
                        <div className="flex flex-wrap justify-around">
                            <div className="column-wrap">
                                <div className="icon-wrap rounded-full bg-gray-bg-1 p-9 tab1:p-6 flex items-center justify-center w-fit h-fit">
                                    <RiUserSettingsLine className="text-purple"/>
                                </div>

                                <div className="descr-wrap">
                                    <div className="title">
                                        <span className="pt-2 block">SERVICES</span>
                                    </div>

                                    <div className="content">
                                        <span>Medical Design, Branding, App, Development</span>
                                    </div>
                                </div>
                            </div>

                            <div className="column-wrap">
                                <div className="icon-wrap rounded-full bg-gray-bg-1 p-9 tab1:p-6 flex items-center justify-center w-fit h-fit">
                                    <RiUserSettingsLine className="text-purple"/>
                                </div>

                                <div className="descr-wrap">
                                    <div className="title">
                                        <span className="pt-2 block">CLIENT</span>
                                    </div>

                                    <div className="content">
                                        <span>UXERA SDN BHD</span>
                                    </div>
                                </div>
                            </div>

                            <div className="column-wrap">
                                <div className="icon-wrap rounded-full bg-gray-bg-1 p-9 tab1:p-6 flex items-center justify-center w-fit h-fit">
                                    <RiUserSettingsLine className="text-purple"/>
                                </div>

                                <div className="descr-wrap">
                                    <div className="title">
                                        <span className="pt-2 block">DATE</span>
                                    </div>

                                    <div className="content flex flex-col">
                                        <span>Start: 29 April, 2023</span>
                                        <span>End: 15 July, 2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="projectContent-container flex flex-row sdesk1:flex-col">
                        <div className="content-wrap w-2/3 sdesk1:w-full pr-4 sdesk1:pr-0">
                            {portfolio.body.map((item, key) => (
                                <div key={key}>
                                    {item.type === 'text'
                                    ? <div>
                                        <div>
                                            <span className="header-black">{item.content.header}</span>
                                        </div>

                                        <div>
                                            <span className="paragraph">{item.content.paragraph}</span>
                                        </div>
                                    </div>
                                    : item.type === 'paragraph'
                                    ? <div>
                                        <div>
                                            <span className="paragraph">{item.content}</span>
                                        </div>
                                    </div>
                                    : item.type === 'image'
                                    ? <div className="py-10 flex flex-wrap">
                                        {item.image.map((img, key) => (
                                            <div className="pr-6 pb-6 w-1/4 tab1:w-1/2 ">
                                                <div className="overflow-hidden rounded-2xl aspect-square">
                                                    <img src={img} alt={portfolio.name} className="object-contain w-full"></img>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    : ''
                                    }
                                </div>
                            ))}
                        </div>

                        <div className="sideInfo-wrap">
                            <div className="bg-purple-pri rounded-[30px] overflow-hidden p-12">
                                <div className="rowInfo-wrap">
                                    <div>
                                        <span className="text-3xl text-white font-semibold tracking-wider">Need Any Help?</span>
                                    </div>

                                    <div>
                                        <span className="block text-md text-white tracking-wider pt-2 pb-10">Need any help? Call us 24/7 for support</span>
                                    </div>
                                </div>

                                <div className="rowInfo-wrap contactdetails">
                                    <div>
                                        <span className="flex flex-row items-start justify-start w-full text-white gap-4 tracking-wider">
                                            <div className="w-fit block pt-1"><FaPhoneAlt className="block !w-4"/></div>
                                            +6011-2331 3110
                                        </span>
                                    </div>

                                    <div>
                                        <span className="flex flex-row text-white gap-4 tracking-wider text-ellipsis overflow-hidden">
                                            <div className="w-fit block pt-1"><TbMailFilled className="block !w-4"/></div>
                                            contact@mxisolutions.com
                                        </span>
                                    </div>

                                    <div>
                                        <span className="flex flex-row  text-white gap-4 tracking-wider">
                                            <div className="w-fit block pt-1"><FaLocationDot className="block !w-4"/></div>
                                            A-11-3A, Jalan Selaman 1/1, 68000, Ampang, Selangor, Malaysia
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PortfolioDetail;