import Header from "../../Components/All/Header";
import Menu from "../../Components/Service/Menu";
import Contact from "../../Components/Service/Contact";
import Blog from "../../Components/Service/Blog";

import './Service.css';

const Service = () => {

	var today = new Date();
	var month = today.toLocaleString('default', { month: 'long' });
	var day = today.getDate();
	var year = today.getFullYear();
	var currDate = day + ' ' + month + ', ' + year;

	const blogs =[
		{
			title: 'Your Business Safe Ensure High Availability',
			desc: 'Lorem ipsum dolor sit amet consectetur. Eu vel diam enim a. Ut ut enim volutpat dictum adipiscing viverra adipiscing molestie.',
			img: '/Images/About/abouthero.png',
			link:'/blog',
			date: currDate,
			cat: 'It Services'
		},
		{
			title: 'Your Business Safe Ensure High Availability',
			desc: 'Lorem ipsum dolor sit amet consectetur. Eu vel diam enim a. Ut ut enim volutpat dictum adipiscing viverra adipiscing molestie.',
			img: '/Images/About/abouthero.png',
			link:'/blog',
			date: currDate,
			cat: 'It Services'
		},
		{
			title: 'Your Business Safe Ensure High Availability',
			desc: 'Lorem ipsum dolor sit amet consectetur. Eu vel diam enim a. Ut ut enim volutpat dictum adipiscing viverra adipiscing molestie.',
			img: '/Images/About/abouthero.png',
			link:'/blog',
			date: currDate,
			cat: 'It Services'
		},
	]

	return (
		<div className="service-page overflow-hidden">
			<Header title="Service"></Header>

			<Menu></Menu>

			<Contact></Contact>

			<Blog items={blogs}></Blog>
		</div>
	);
}
export default Service;