import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const Menu = () => {
	const posts = [
        {
            title: "Technology Solutions",
            img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
            content: "react tailwind css card with image It is a long established fact that a reader will be distracted by the readable content"
        },
        {
            title: "Technology Solutions",
            img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
			content: "react tailwind css card with image It is a long established fact that a reader will be distracted by the readable content"
        },
        {
            title: "Technology Solutions",
            img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
            content: "react tailwind css card with image It is a long established fact that a reader will be distracted by the readable content"
        },
        {
            title: "Technology Solutions",
            img: "https://cdn.pixabay.com/photo/2019/12/17/14/43/christmas-4701783__340.png",
            content: "react tailwind css card with image It is a long established fact that a reader will be distracted by the readable content"
        },
    ];

	return (
		<div className="section service-container flex justify-center pb-24 pt-6 px-20 ssdesk1:px-12 stab1:px-8 smob1:px-4">
			<div className="max:w-[1400px] pb-0 flex justify-between flex-wrap stab1:!px-0 smob1:!px-0 sdesk1:!pt-6">
				{posts.map((items, key) => (
					<div className="service mb-8" key={key}>
						<Link to="/service/a" className="">
							<div className="w-full border border-[#B0B0B0] rounded-3xl pt-16 px-16 smob1:px-8 pb-12 h-full">
								<div className="icon w-20 h-20 rounded-xl overflow-clip">
									<img className="object-cover h-full w-full" src={items.img} alt="hehe" />
								</div>

								<div className="title text-2xl py-6">
									{items.title}
								</div>

								<p className="descr pb-14">
								{items.content}
								</p>

								<div className="btn-wrapper">
									<Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
								</div>
							</div>
						</Link>
					</div>
				))}
			</div>
		</div>
	);
}
export default Menu;