import { useState } from 'react';

import { Icon } from '@iconify/react';

import './Home.css';

import ReviewSlider, { ProjectCarousel } from '../../Components/Utils/slider';
import { DoublePurpleDotHeader, PurpleDotHeader, PurpleDotHeaderWhite } from '../All/PurpleDotHeader';
// import Loading from '../Loading/Loading';

const reviews = [
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Abdulla Ahmed",
        "company": "Qatar News Agency(QNA)"
    },
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Nik Aqim",
        "company": "Qatar News Agency(QNA)"
    },
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Abdulla Ahmed",
        "company": "Qatar News Agency(QNA)"
    },
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Abdulla Ahmed",
        "company": "Qatar News Agency(QNA)"
    },
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Abdulla Ahmed",
        "company": "Qatar News Agency(QNA)"
    },
    {
        "message" : "Working with [Company Name] was a fantastic experience. They took the time to understand our business needs and delivered a custom software solution that exceeded our expectations.",
        "name":"Abdulla Ahmed",
        "company": "Qatar News Agency(QNA)"
    }
];

const Banner1 = () => {
    return(
        <div className='banner1-container'>
            <div className='cntnt-wrapper'>
				<PurpleDotHeader title="WELCOME TO OUR COMPANY"/>

                <div className='main-title title-7xl pt-8'>
                    Clear Thinking Makes 
                    <span className='text-[#5B62FF]'>Bright Future !</span>
                </div>

                <div className='paragraph-gray des:mt-6 tab1:mt-6'>
                    The website design should be user-friendly, easy to navigate, and aesthetically pleasing. It should be optimized for fast loading times, and the layout should be consistent across all pages.
                </div>

                <div className='btn-wrapper'>
                    <div className='cta-btn des:mt-16 mob:mt-12'>
                        Discover More
                    </div>
                    <div className='discover-btn'>

                    </div>
                </div>
            </div>

            <div className='bg-wrapper absolute right-0 top-[0%] max-w-[40%] tab1:top-0'>

                <svg className="w-[122%] absolute top-[-10%] right-[-8%]" width="120%" height="120%" viewBox="0 0 831 1085" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 547.653L912 2V1083L2 547.653Z" stroke="#5B62FF" strokeWidth="2"/>
                </svg>

                <img className="max-w-[100%]" src='/Assets/images/Banner1Image.png'></img>
            </div>
        </div>
    )
};

const ServiceContainer = () => {
    return(
        <div className='service-container'>
            <div className='max-w-[1400px] px-20 flex justify-center flex-wrap mx-auto pt-[240px] relative'>
                <div className='header flex flex-col items-center w-full'>
					<DoublePurpleDotHeader title="OUR SERVICES"/>

                    <div className='header-black tab1:text-center'>
                        Service We Provide
                    </div>

                    <div className='paragraph-gray text-center des:max-w-[60%] mx-auto'>
                        Lorem ipsum dolor sit amet consectetur. Egestas congue massa dictum sagittis praesent at eget tempus. Turpis ultrices gravida id ultrices.
                    </div>
                </div>

                <div className='content mt-12 flex items-start justify-between w-full flex-wrap'>
                
                    <div className='service-wrapper'>
                        <div className='icon'>

                        </div>

                        <div className='title'>
                            Technology Solutions
                        </div>

                        <div className='descr'>
                            Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                        </div>

                        <div className='btn-wrapper'>
                            <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                        </div>
                    </div>

                    <div className='service-wrapper'>
                        <div className='icon'>

                        </div>

                        <div className='title'>
                            Technology Solutions
                        </div>

                        <div className='descr'>
                            Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                        </div>

                        <div className='btn-wrapper'>
                            <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                        </div>
                    </div>

                    <div className='service-wrapper'>
                        <div className='icon'>

                        </div>

                        <div className='title'>
                            Technology
                        </div>

                        <div className='descr'>
                            Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                        </div>

                        <div className='btn-wrapper'>
                            <Icon className="text-4xl text-purple-pri rotate-45" icon="ion:arrow-up" />
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    )
};

const AboutUsContainer = () => {
    return(
        <div className='aboutUs-container'>
            <div className='max-w-[1400px] px-20 flex justify-between flex-wrap mx-auto pb-[240px] relative'>
                <div className='content-container max-w-[40%]'>
					<PurpleDotHeader title="WELCOME TO OUR COMPANY"/>

                    <div className='header-black pt-8'>
                        We Are Increasing Business Success With Technology
                    </div>

                    <div className='paragraph-gray mt-6 tab1:!mt-4'>
                        The website design should be user-friendly, easy to navigate, and aesthetically pleasing. It should be optimized for fast loading times, and the layout should be consistent across all pages.
                        <ul className='list-disc pl-10 mt-6  text-black'>
                            <li>Amazing Communication</li>
                            <li>Best trending designing experience</li>
                            <li>Email & live chat</li>
                        </ul>
                    </div>

                    <div className='btn-wrapper'>
                        <div className='cta-btn mt-16'>
                            Discover More
                        </div>
                        <div className='discover-btn'>

                        </div>
                    </div>
                </div>

                <div className='img-container w-[80%] max-w-[50%] relative top-[-3.5rem]'>
                    <div className='img-large max-w-[80%] absolute right-0 rotate-[-10deg]'>
                        <img className="w-full rounded-2xl" src="/Assets/images/image-placeholder.png" alt="Call us!"/>
                    </div>
                    <div className='img-small max-w-[40%] absolute left-0 bottom-[-80px]'>
                        <img className="w-full rounded-2xl border-8 border-white outline-transparent" src="/Assets/images/image-placeholder.png" alt="Call us!"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ContactUsCTA = () => {
    return(
        <div className='contactUsCTA-container w-full relative'>
            <div className='bg-wrapper w-full max-w-full relative overflow-hidden'>
                <img className="w-full h-full object-cover object-[40%_33%]" src='/Assets/images/contactUs-bg.png' alt="Teamwork"/>
                <svg className="purple-vector z-20 h-full absolute top-0 left-[10%]" width="589" height="461" viewBox="0 0 589 461" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M589 -2H232.5L0 615H345L589 -2Z" fill="#100584" fillOpacity="0.31"/>
                </svg>
                <svg className="grey-vector z-10 h-[150%] absolute top-[-88px] left-[10px]" width="473" height="459" viewBox="0 0 473 459" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 609.5V0.5H114L473 609.5H0Z" fill="#797979" fillOpacity="0.47"/>
                </svg>

            </div>
            <div className='content-wrapper absolute w-full max-w-[1400px] px-20 flex justify-between items-center flex-wrap inset-0 z-20 mx-auto'>
                <div className='content'>
                    <div className='sub-header text-white test-xl w-full'>
                        WE ARE HERE TO ANSWER YOUR QUESTION 24/7
                    </div>
                    <div className='header-white !py-0'>
                        Need A Consultation ?
                    </div>
                </div>

                <div className='cta-btn mt-16 font-bold'>
                    Let's Get Started
                </div>
            </div>
        </div>
    )
};

const ContactUsCTA2 = () => {
    return(
        <div className='ContactUsCTA2-container overflow-clip'>
            <div className='max-w-[1400px] px-20 flex justify-between flex-wrap mx-auto py-[240px] relative'>
                <div className='content-container max-w-[40%]'>
					<PurpleDotHeader title="WELCOME TO OUR COMPANY" />

                    <div className='header-black pt-8'>
                        We Are Increasing Business Success With Technology
                    </div>

                    <div className='btn-wrapper flex justify-left items-center  mt-16'>
                        <div className='cta-btn mr-4'>
                            Start A Project
                        </div>
                        <div className='contactDetails'>
                            <span className='text-purple-pri font-bold'>Call Us:+603-4270 6883</span>
                            <br/>
                            <span className="text-[#85858C]">For any question</span>
                        </div>
                    </div>
                </div>

                <div className='img-container w-[80%] max-w-[50%] relative top-[-3.5rem]'>
                    <div className='img-large max-w-[80%] absolute right-0'>
                        <img className="object-cover rounded-2xl" src="/Assets/images/image-placeholder.png" alt="Call MXI Solutions"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

const StrongPointContainer = () => {
    return(
        <div className='pointers-container border-y border-[#D9D9D9]'>
            <div className='max-w-[1400px] px-20 flex justify-between flex-wrap mx-auto py-32 relative'>
                <div className='pointers-wrapper w-[30%] flex justify-start items-center'>
                    <div className='circle bg-[#5B62FF] mr-4'></div>
                    <div className='content'>
                        <span className='text-6xl font-bold'>200+</span><br/>
                        <span className='text-xl'>TEAM MEMBER</span>
                    </div>
                </div>

                <div className='pointers-wrapper w-[30%] flex justify-center items-center'>
                    <div className='circle bg-[#5B62FF] mr-4'></div>
                    <div className='content'>
                        <span className='text-6xl font-bold'>200+</span><br/>
                        <span className='text-xl'>TEAM MEMBER</span>
                    </div>
                </div>

                <div className='pointers-wrapper w-[30%] flex justify-end items-center'>
                    <div className='circle bg-[#5B62FF] mr-4'></div>
                    <div className='content'>
                        <span className='text-6xl font-bold'>200+</span><br/>
                        <span className='text-xl'>TEAM MEMBER</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

const CustomerFeedbackContainer = () => {
    return(
        <div className='CustomerFeedback-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto py-36 relative'>
                <div className='header-wrapper'>
					<PurpleDotHeaderWhite title="CLIENT TESTIMONIAL"/>

                    <div className='header-white pt-8'>
                        About Customer Stories
                    </div>
                </div>

                <div className='content-wrapper w-full mt-20'>
                    <ReviewSlider data={reviews} slidesPerView={2} />
                </div>
                

            </div>
        </div>
    )
};

const TeamMemberContainer = () => {
    const [isHovering, setIsHovering] = useState({
        'one': false,'two': false,'three': false,'four': false
    });

    const handleMouseOver = (param) => {
        console.log('on mouseover', param)
        if(param === 'one'){
            setIsHovering({'one': true,'two': false,'three': false,'four': false});
        } else if(param === 'two'){
            setIsHovering({'one': false,'two': true,'three': false,'four': false});
        } else if(param === 'three'){
            setIsHovering({'one': false,'two': false,'three': true,'four': false});
        } else if(param === 'four'){
            setIsHovering({'one': false,'two': false,'three': false,'four': true});
        } 
    };

    const handleMouseOut = () => {
        console.log('onmouseout')
        setIsHovering({'one': false,'two': false,'three': false,'four': false});
    };

    const members = [{
            id:'one',
            name: 'Muniyati Muhamad',
            position: 'CEO' 
        },
        {
            id:'two',
            name: 'Nik Aqim',
            position: 'CTO' 
        },
        {
            id:'three',
            name: 'Dzikrullah',
            position: 'Software Engineer'
        },
        {
            id:'four',
            name: 'Rashila Asib',
            position: 'Funding Manager'
        }];

    const MemberContainer = ({isHovering, data, onMouseOver, onMouseLeave }) => {
        console.log('399:', data.id)
        return(
            <div className='expert-wrapper relative' onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                <div className='image-wrapper w-full h-full'>
                    <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png'/>
                </div>

                <div className={`hover-el absolute bottom-[5%] w-[90%] h-[60%] inset-x-0 text-center p-5 bg-white mx-auto rounded-xl ${ isHovering[data.id] ? "block" : "hidden" }`}>
                    <span className='text-2xl text-purple-pri font-semibold'>{data.name}</span><br/>
                    <span className='text-base'>{data.position}</span>
                    <div className='socmed-wrapper mt-4 py-4 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between'>
                        <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                            <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                        </div>

                        <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                            <Icon className="text-xl text-white" icon="uil:twitter" />
                        </div>

                        <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                            <Icon className="text-xl text-white" icon="uil:linkedin" />
                        </div>

                        <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                            <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                        </div>
                    
                    </div>

                </div>
            </div>
    )};

    return(
        <div className='TeamMember-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto py-36 relative'>

                <div className='flex flex-col items-center w-full'>
					<DoublePurpleDotHeader title="TEAM MEMBERS" />

                    <div className='header-black py-7 text-center'>
                        Our Top Skilled Experts
                    </div>
                </div>

                <div className='content-wrapper w-full mt-20 flex flex-wrap justify-around items-center'>
                    {/* {
                        members.map((member) => {
                           return <MemberContainer isHovering={isHovering} data={member} onMouseOver={() => handleMouseOver(member.id)} onMouseLeave={handleMouseOut} />
                        })
                    } */}

                    <div className='expert-wrapper relative' onMouseOver={ () => handleMouseOver('one')} onMouseLeave={handleMouseOut}>
                        <div className='image-wrapper w-full h-full'>
                            <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png'/>
                        </div>

                        <div className={`hover-el absolute bottom-[5%] w-[90%] h-[60%] inset-x-0 text-center p-5 bg-white mx-auto rounded-xl ${ isHovering.one ? "block" : "hidden" }`}>
                            <span className='text-2xl text-purple-pri font-semibold'>Nik Aqim</span><br/>
                            <span className='text-base'>CEO & Founder</span>
                            <div className='socmed-wrapper mt-4 py-4 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between'>
                                <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                    <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                                </div>

                                <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                    <Icon className="text-xl text-white" icon="uil:twitter" />
                                </div>

                                <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                    <Icon className="text-xl text-white" icon="uil:linkedin" />
                                </div>

                                <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                    <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                                </div>
                            
                            </div>

                        </div>
                    </div>

                    <div className='expert-wrapper relative' onMouseOver={() => handleMouseOver('two')} onMouseLeave={handleMouseOut}>
                        <div className='image-wrapper w-full h-full'>
                            <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png'/>
                        </div>

                        { isHovering.two && (
                            <div className='hover-el absolute bottom-[5%] w-[90%] h-[60%] inset-x-0 text-center p-5 bg-white mx-auto rounded-xl'>
                                <span className='text-2xl text-purple-pri font-semibold'>Nik Aqim</span><br/>
                                <span className='text-base'>CEO & Founder</span>
                                <div className='socmed-wrapper mt-4 py-4 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between'>
                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:twitter" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:linkedin" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                                    </div>
                                
                                </div>

                            </div>
                        )}
                    </div>

                    <div className='expert-wrapper relative' onMouseOver={() => handleMouseOver('three')} onMouseLeave={handleMouseOut}>
                        <div className='image-wrapper w-full h-full'>
                            <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png'/>
                        </div>

                        { isHovering.three && (
                            <div className='hover-el absolute bottom-[5%] w-[90%] h-[60%] inset-x-0 text-center p-5 bg-white mx-auto rounded-xl'>
                                <span className='text-2xl text-purple-pri font-semibold'>Nik Aqim</span><br/>
                                <span className='text-base'>CEO & Founder</span>
                                <div className='socmed-wrapper mt-4 py-4 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between'>
                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:twitter" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:linkedin" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                                    </div>
                                
                                </div>

                            </div>
                        )}

                    </div>

                    <div className='expert-wrapper relative' onMouseOver={() => handleMouseOver('four')} onMouseLeave={handleMouseOut}>
                        <div className='image-wrapper w-full h-full'>
                            <img className='w-full h-full object-cover' src='/Assets/images/image-placeholder.png'/>
                        </div>

                        { isHovering.four && (
                            <div className='hover-el absolute bottom-[5%] w-[90%] h-[60%] inset-x-0 text-center p-5 bg-white mx-auto rounded-xl'>
                                <span className='text-2xl text-purple-pri font-semibold'>Nik Aqim</span><br/>
                                <span className='text-base'>CEO & Founder</span>
                                <div className='socmed-wrapper mt-4 py-4 border-t border-[#D9D9D9] flex flex-wrap items-center justify-between'>
                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="ri:facebook-fill" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:twitter" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="uil:linkedin" />
                                    </div>

                                    <div className='socmed w-10 h-10 rounded-[50%] bg-purple-pri flex justify-center items-center hover:bg-[#3f49ff] cursor-pointer'>
                                        <Icon className="text-xl text-white" icon="mingcute:pinterest-fill" />
                                    </div>
                                
                                </div>

                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
};


// project section
const projectsData = [{
        id:"1",
        name:"Corporate Website",
        category:"In-house Projects",
        },{
            id:"2",
            name:"Election Monitoring",
            category:"WebApp Development",
        },{
            id:"3",
            name:"Ecommerce",
            category:"WebApp Development",
        },{
            id:"4",
            name:"FIS BURSA EUAT",
            category:"Support & Maintenance",
        },{
            id:"5",
            name:"JR-Stich SDN BHD",
            category:"Landing Page",
        },{
            id:"6",
            name:"Cloud Infrastructure",
            category:"Support and Maintenance",
}];

const Project = (props) => {
    return(
        <div className='project-wrapper w-full h-[500px] relative flex items-center rounded-xl' onMouseOver={props.handleHover} onMouseLeave={props.quitHover}>
            <div className='image-wrapper w-full h-full'>
                <img className='w-full h-full object-cover rounded-xl' src='/Assets/images/image-placeholder.png' alt="Project"/>
            </div>

            <div className={`hover-el absolute bottom-[5%] w-[90%] h-[90%] inset-x-0 text-center p-5 bg-purple-pri/80 mx-auto rounded-xl flex justify-center items-center flex-wrap content-center  ${ props.isHovering[props.data.id] ? "block" : "hidden" }`}>
                <div className='icon-wrapper bg-white w-16 h-16 rounded-[50%] flex justify-center items-center relative bottom-5'>
                    <Icon icon="iconamoon:search" />
                </div>

                <div className='descr-wrapper w-[80%] text-left absolute bottom-7 left-7 truncate overflow-hidden'>
                    <span className='text-white text-sm'>{props.data.category}</span><br/>
                    <span className='text-white text-4xl font-semibold'>{props.data.name}</span>

                </div>
            </div>
        </div>
    )

};

const ProjectContainer = () => {

    const [isHovering, setIsHovering] = useState({
    });


    // defining project component states
    if(Object.keys(isHovering).length < 1){
        let projectStates = {...isHovering};
    
        for(let i=0; i < projectsData.length; i++){
            projectStates[projectsData[i].id] = false;
        }

        setIsHovering({...projectStates});
    }

    // handle mouse-in
    const handleMouseOver = (id) => {
        console.log('on mouseover id:', id)

        let copyObject = {...isHovering};

        copyObject[id] = true;

        setIsHovering({...copyObject});
    };

    // handle mouse-out
    const handleMouseOut = () => {
        console.log('onmouseout...')
        let copyObject = {...isHovering};

        Object.keys(copyObject).forEach(v => copyObject[v] = false)

        setIsHovering({...copyObject});
    };

    const CarouselSwiperOption = {
        slidesPerView: 4,
        spaceBetween: 10,
        breakpoints: {
            0: {
            slidesPerView: 1,
            spaceBetween: 10,
            },
            689: {
            slidesPerView: 2,
            spaceBetween: 10,
            },
            989: {
            slidesPerView: 3,
            spaceBetween: 10,
            },
            1150: {
            slidesPerView: 4,
            spaceBetween: 32,
            },
        },
    };

    // construct projects component
    const allprojects = [];
    for(let i=0; i < projectsData.length; i++){
        allprojects.push(<Project key={projectsData[i].id} data={projectsData[i]} isHovering={isHovering} handleHover={() => handleMouseOver(projectsData[i].id)} quitHover={ ()=> handleMouseOut() } />);
    }

    return(
        <div className='Project-container'>
            <div className='max-w-[1400px flex items-center flex-wrap mx-auto pb-36 relative'>
                <div className='header-wrapper w-full px-20'>
					<PurpleDotHeader title="OUR PROJECTS"/>

                    <div className='header-black pt-8'>
                        Our Successful Projects
                    </div>
                </div>

                <div className='content-wrapper w-full pt-20 px-3 flex flex-wrap justify-between items-baseline'>
                    <ProjectCarousel data={allprojects} carouselSwiperOption={CarouselSwiperOption}/>
                </div>
            </div>
        </div>
    )
};

// end of project section


// marketing ads
const LocalAdsContainer = () => {
    return(
        <div className='LocalAds-container bg-[red] relative'>
            <div className='max-w-[1400px] px-20 flex justify-between flex-wrap mx-auto py-20 relative'>
                <div className='content-container max-w-[60%]'>
					<PurpleDotHeaderWhite title="WELCOME TO OUR COMPANY"/>

                    <div className='header-white pt-8'>
                        We Are Increasing Business Success With Technology
                    </div>

                    <div className='paragraph-white mt-6 tab1:!mt-4'>
                        The website design should be user-friendly, easy to navigate, and aesthetically pleasing. It should be optimized for fast loading times, and the layout should be consistent across all pages.
                    </div>

                    <div className='btn-wrapper'>
                        <div className='cta-btn mt-16'>
                            Discover More
                        </div>
                        <div className='discover-btn'>

                        </div>
                    </div>
                </div>

                <div className='img-container w-[40%] max-w-[40%] relative'>
                    <div className='img-large max-w-[80%] absolute right-0'>
                        <img className="w-full rounded-2xl" src="/Assets/images/image-placeholder.png"/>
                    </div>
                </div>
            </div>

            <div className='section-footer bg-purple-pri flex items-end justify-between absolute w-[70%] max-w-3xl bottom-[-1px] right-0 text-white px-16 py-6 text-md'>
                <span>Fast 24/7 Customer Service</span><hr className='w-[30%] h-3'/>
                <span>Save time & valuable money</span>
            </div>
        </div>
    )
};

// Blogs section
const BlogData = [{
    date: "20230604",
    category: "It services",
    title: "Your Business Safe Ensure High Availability",
    paragraph: "We’ve been a strategy thought leader for nearly five decades and we bring But we ipsum dolor sit amet, consectetur adipisicing elit sed eiusmod tempor"
},{
    date: "20230605",
    category: "Circuitry",
    title: "Data Backup and Recovery Best Practices Small",
    paragraph: "We’ve been a strategy thought leader for nearly five decades and we bring But we ipsum dolor sit amet, consectetur adipisicing elit sed eiusmod tempor"
}];

const Blog= (props) => {
    let date = props.data.date;
    let dateObj = new Date();

    dateObj.setFullYear(parseInt(date.slice(0,3)));
    dateObj.setMonth(parseInt(date.slice(4,6)));
    dateObj.setDate(parseInt(date.slice(6,8)));

    return(
        <div className='blog-container'>
            <div className='banner-wrapper w-full h-[275px]'>
                <img className="w-full h-full object-cover rounded-t-xl" src="/Assets/images/image-placeholder.png" alt="Blog"/>
            </div>

            <div className='content-wrapper py-16 px-8'>
                <div className='metadata-wrapper flex items-center'>
                    <div className='date'>
                        <div className='icon text-base'>
                            <Icon icon="bx:calendar" />
                        </div>
                        <div className='value text-black'>
                            { dateObj.toDateString().split(' ').slice(1).join('/') }
                        </div>
                    </div>
                    <div className='category'>
                        <div className='icon text-lg'>
                            <Icon icon="material-symbols:folder-open-outline" />
                        </div>
                        <div className='value'>
                            { props.data.category }
                        </div>
                    </div>
                </div>

                <div className='descr-wrapper'>
                    <div className='title text-4xl font-semibold py-3'>
                        { props.data.title }
                    </div>
                </div>

                <div className='read-btn text-base text-black'>
                    { props.data.paragraph }
                </div>
            </div>
        </div>
    )
};


const BlogsContainer = () => {
    const allblogs = [];
    
    for(let i=0; i < BlogData.length; i++){
        allblogs.push(<Blog key={i} data={BlogData[i]} />)
    }

    return(
        <div className='blogs-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto py-36 relative'>

                <div className='flex items-center flex-col w-full'>
					<DoublePurpleDotHeader title="NEWS POSTS"/>

                    <div className='header-black'>
                        Latest Blog Updates
                    </div>
                </div>

                <div className='content-wrapper w-full mt-20 flex flex-wrap justify-between items-center'>
                    {allblogs}
                </div>

            </div>
        </div>
    )
};
// end of Blogs section

// Our clients section
const clientsData = [{
    name: 'Ava Moony',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'QNA',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'UXERA',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'JR STITCH',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'HD TECHNOLOGY',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'TRIPS4U',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'GLITZ DESIGN',
    imgpath: '/Assets/images/image-placeholder.png',
},{
    name: 'BERNAMA NEWS',
    imgpath: '/Assets/images/image-placeholder.png',
}];

const Client = (props) => {
    return(
        <div className='client'>
            <div className='logo w-12 h-12 mr-3 rounded-[50%] overflow-clip' >
                <img className="w-full h-full object-cover" src={ props.data.imgpath } alt=""/>
            </div>
            <div className='name font-bold text-xl truncate uppercase'>
                { props.data.name }
            </div>
        </div>
    )
};

const ClientContainer = () => {
    const clientHtml = [];

    for(let i=0; i < clientsData.length; i++){
        clientHtml.push(<Client key={i} data={clientsData[i]} />);
    };

    return(
        <div className='clients-container'>
            <div className='max-w-[1400px] px-20 flex items-center flex-wrap mx-auto pb-40 relative'>

                <div className='header flex flex-col items-center w-full'>
					<DoublePurpleDotHeader title="OUR CLIENTS"/>

                    <div className='header-black text-center'>
                        Trusted and Happy Clients
                    </div>
                </div>

                <div className='content-wrapper'>
                    {clientHtml}
                </div>

            </div>
        </div>
    )
}; 

export {
	Banner1,
	ServiceContainer,
	AboutUsContainer,
	ContactUsCTA,
	ContactUsCTA2,
	StrongPointContainer,
	CustomerFeedbackContainer,
	TeamMemberContainer,
    Project,
	ProjectContainer,
	LocalAdsContainer,
	BlogsContainer,
	ClientContainer,
}