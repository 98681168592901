import { Link } from "react-router-dom";
import { FaCalendarAlt, FaFolderOpen } from "react-icons/fa"

const Blog = ({items}) => {
	return (
		<div className="section blog-container w-full flex justify-center pt-32 smob1:pt-20 pb-32 mob:bg-[#F3F3F3]">
			<div className="max:w-[1400px] flex flex-wrap justify-between">
				{items.map((item, key) => (
					<div key={key} className="blog mb-8">
						<div className="border rounded-2xl border-[#AAAAAA] overflow-hidden p-8 smob1:py-8 smob1:px-4 text-[#1C1C25] bg-white">
							<div>
								<img src={item.img} alt={item.title} className="object-cover aspect-[5/3]"/>
							</div>

							<div className="pt-12 pb-10 flex flex-col">
								<div className="flex flex-row mb-4">
									<div className="flex flex-row mr-6 items-center">
										<div className="mr-1"><FaCalendarAlt size="20" color="#272DF1"/></div>

										<div className="smob1:text-xs">{item.date}</div>
									</div>
										
									<div className="flex flex-row items-center">
										<div className="mr-1"><FaFolderOpen size="20" color="#272DF1"/></div>

										<div className="smob1:text-xs">{item.cat}</div>
									</div>
								</div>

								<div className="mb-6"><span className="text-2xl smob1:text-xl font-semibold">{item.title}</span></div>

								<div><p className="text-lg smob1:text-base text-[#A0A0A0]">{item.desc}</p></div>
							</div>

							<div>
								<Link to={item.link} className="block w-fit bg-[#272DF1] textlgl text-white font-semibold px-6 py-2 rounded-full tracking-wider hover:bg-[#454bfe]">
									Read More
								</Link>
							</div>	
						</div> 
					</div>
				))}
			</div>
		</div>
	);
}
export default Blog;