import { Link } from "react-router-dom";
import Header from "../../Components/All/Header";
import { BsArrowRight, BsArrowRightCircle } from 'react-icons/bs'
import { FiClock } from 'react-icons/fi'
import Question from "../../Components/Service/Question";

import './ServiceDetail.css';

const WorkingHours = () => {
	return (
		<div className="workingHInfo-container rounded-[30px] bg-gray-bg-3 p-[12%]">
			<div className="mb-8">
				<span className="title text-3xl font-medium tracking-wide">Working Hours</span>
			</div>

			<div className="hourInfo-container">
				<div className="flex flex-row gap-4 items-center text-lgray1 bg-white p-6 rounded-[12px] mt-4">
					<FiClock size="20"/>
					<span className="datetime text-base font-semibold tracking-wider">
						Monday - Friday 0800 - 1930
					</span>
				</div>

				<div className="flex flex-row gap-4 items-center text-lgray1 bg-white p-6 rounded-[12px] mt-4">
					<FiClock size="20"/>
					<span className="datetime text-base font-semibold tracking-wider">
						Monday - Friday 0800 - 1930
					</span>
				</div>

				<div className="flex flex-row gap-4 items-center text-lgray1 bg-white p-6 rounded-[12px] mt-4">
					<FiClock size="20"/>
					<span className="datetime text-base font-semibold tracking-wider">
						Monday - Friday 0800 - 1930
					</span>
				</div>
			</div>
		</div>
	);
}

const Services = ({services}) => {
	return (
		<div className="servicesInfo-container rounded-[30px] bg-gray-bg-3 px-[12%] py-[12%] mb-7 tab1:mb-6">
			<div className="header-wrap mb-4">
				<span className="title text-3xl font-medium tracking-wide">All Services</span>
			</div>

			<div className="servicesList-wrap">
				{services.map((item, key) => (
					<Link to={ '/service/' + item.link} key={key} className="flex flex-row justify-between w-full py-3 border-b border-divide-border-gray text-lgray1 hover:text-black hover:border-divide-border-l">
						<span className="name text-lg">{item.name}</span>
						<BsArrowRight className="icon" size="20" color="#8E8E8E"/>
					</Link>
				))}
			</div>
		</div>
	);
}

const BlogContainer = ({serviceData}) => {
	return (
		<div className="detail-container flex flex-wrap sdesk1:w-full">
			<div className="img-wrap w-full aspect-video overflow-clip rounded-2xl mb-24">
				<img src={serviceData.banner} alt="banner" className="w-full h-full object-cover"/>
			</div>

			{serviceData.body.map((item) => (
				<div>{item.type === 'text'
					? <div>
						<div>
							<span className="block text-5xl font-bold mb-9 smob1:mb-4 tab1:text-4xl smob1:text-3xl">
								{item.content.header}
							</span>
						</div>

						<div><p className="paragraph">{item.content.paragraph}</p></div>
					</div>
					: item.type === 'imageAndPoint'
					? <div>
						<div className="image-pointer-container flex flex-wrap flex-row rounded-[30px] overflow-hidden bg-gray-bg-3 my-16 tab1:flex-col">
							<div className="image-container w-1/2 rounded-[30px] overflow-clip tab1:w-full tab1:aspect-video">
								<img src={item.content.image} alt={item.content.header} className="object-cover w-full h-full"/>
							</div>

							<div className="pointers-container">
								<span className="title pb-6 block text-3xl w-full smob1:text-2xl smob1:pb-4">{item.content.header}</span>

								<ul className="w-full">
								{item.content.list.map((item, key) => (
									<li key={key} className="py-2 flex flex-row gap-4 items-center text-lgray1 text-lg smob1:text-base"><BsArrowRightCircle size="20" color="#5B62FF" />{item}</li>
								))}
								</ul>
							</div>
						</div>
					</div> 
					: ''
				}</div>
			))}

			<div>
				<div className="image-col-container flex flex-wrap my-9 justify-between smob1:mt-9 smob1:mb-4">
					{serviceData.images.map((item) => (
						<div className="image-col-wrap w-[48%] tab1:w-full">
							<div className="rounded-[30px] overflow-clip w-full aspect-square">
								<img src={item} alt={serviceData.title} className="object-cover w-full h-full"/>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="faq-container w-full mt-9 smob1:mt-0">
				<div>
					{serviceData.questions.map((item) => (
						<Question item={item}/>
					))}
				</div>
			</div>
		</div>
	);
}

const ServiceDetail = () => {

	const services = [
		{
			name: 'Technology Solution',
			link: '1',
		},
		{
			name: 'Technology Solution',
			link: '2',
		},
		{
			name: 'Technology Solution',
			link: '3',
		}
	]

	const serviceData = {
		title: 'Technology Solution',
		banner: '/Assets/images/image-placeholder.png',
		images: ['/Assets/images/image-placeholder.png', '/Assets/images/image-placeholder.png'],
		body: [
			{
				type: 'text',
				content: {
					header: 'IT Solution And Business',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				}
			},
			{
				type: 'imageAndPoint',
				content: {
					header: 'Service Features',
					list: [
						'We Provide Flexible IT Services',
						'We Provide Flexible IT Services',
						'We Provide Flexible IT Services',
						'We Provide Flexible IT Services'
					],
					image: '/Assets/images/image-placeholder.png'
				}
			},
			{
				type: 'text',
				content: {
					header: 'IT Solution And Business',
					paragraph: 'The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should.'
				}
			}
		],
		questions: [
			{
				question: 'What is the design process for branding?',
				answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
			},
			{
				question: 'What is the design process for branding?',
				answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
			},
			{
				question: 'What is the design process for branding?',
				answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
			},
			{
				question: 'What is the design process for branding?',
				answer: 'Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque. Lorem ipsum dolor sit amet consectetur. Consectetur nec ultrices sed sed. Vivamus dolor cras aliquet scelerisque.'
			}
		],
	}

	return (
		<div className="serviceDetail-page w-full">
			<Header title={'IT Solution And Business'} path={'Services >'}/>

			<div className="serviceDetail-container">
				<div className="flex sdesk1:flex-col justify-between tab1:gap-3">
					<BlogContainer serviceData={serviceData}/>

					<div className="sideInfo-container">
						<Services services={services}/>

						<WorkingHours/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceDetail;

