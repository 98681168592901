import './footer.css';
import { useTranslation } from 'react-i18next';

import { Copyright, Information } from '../../Components/Footer'

function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Information></Information>
      <Copyright></Copyright>
    </div>
  );
}

export default Footer;
