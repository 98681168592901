import { Link } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';

const TopMenu = () => {
  return (
    <div className="w-full topmenu" style={{ borderBottom: '1px solid #E1E1E1' }}>
      <div className="flex h-24 flex-row items-center justify-between px-20 m-auto text-sm mob1:hidden mob1:px-10 sdesk1:px-12 des:es:px-20 max:w-[1400px] sm:text-sm lg:text-sm">
        <div className="w-fit">
          <span>Mon-Fri: 8:00AM - 6:30PM</span>
        </div>
        <div className="flex sm:gap-7 lg:gap-16">
          <Link className="w-fit">All Services</Link>
          <Link className="w-fit">News</Link>
          <Link className="w-fit flex flex-row gap-2 items-center">
            <HiOutlineMail size="26px" />
            contact@mxisolutions.com
          </Link>
        </div>
      </div>
    </div>
  );
};
export default TopMenu;
