import { Link } from 'react-router-dom';


const Menu = ({ handleMenu, dispNavbar }) => {
  const logo = '/Images/Navbar/MXI%20Logo%20Bulat.webp';
  const menuMobile = '/Images/Navbar/NavBtn.svg';

  const menuItems = [
    { id: 0, name: 'Home', link: '/' },
    { id: 1, name: 'About Us', link: '/about' },
    { id: 2, name: 'Services', link: '/service' },
    { id: 3, name: 'Portfolio', link: '/portfolio' },
    { id: 4, name: 'Blog', link: '/blog' },
    { id: 5, name: 'Contact', link: '/contact' },
  ];

  return (
    <div className="w-full menu-container">
      <div className="menu-mobile desk1:py-6 desk1:px-20 flex flex-row items-center justify-between m-auto ssdesk1:px-12 max:w-[1400px] stab1:h-24 stab1:px-8 smob1:h-24 smob1:px-4 sdesk1:h28 sdesk1:px-20 sm:text-md lg:text-xl">
        <div className=" w-fit flex items-center gap-3 max-h-7">
          <img alt="Logo MXI Solutions" className="stab1:h-5 smob1:h-4 ssdesk1:h-7 max-h-7 w-fit object-contain" width="0" height="0" src={logo} />
          <span className="text-base font-bold hidden tab1:block">
            MXI Solutions
          </span>
        </div>

        <div className="flex tab1:hidden mob1:hidden sm:gap-7 lg:gap-16">
          {menuItems.map((item) => (
            <div key={item.id}>
              <Link className="font-bold" to={item.link}>
                {item.name}
              </Link>
            </div>
          ))}
        </div>

        <div className="hidden tab1:block mob1:block max:hidden hover:cursor-pointer">
          <img
            alt="Menu Button"
            src={menuMobile}
            onClick={() => {
              handleMenu(dispNavbar);
              console.log(dispNavbar);
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};
export default Menu;
