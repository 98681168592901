import Header from "../../Components/All/Header";
import { BlogList, BlogMenu } from "../../Components/Blog";


const Blog = () => {

	return (
		<div>
			<Header title="Blogs"/>

			<div className="flex justify-center pb-20">
				<div className="flex flex-wrap max-w-[1400px] w-full px-9">
					<BlogList/>

					<BlogMenu/>
				</div>
			</div>
		</div>
	);
}
export default Blog;