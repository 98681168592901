import { Link } from "react-router-dom";
import {PurpleDotHeaderWhite} from "../../All/PurpleDotHeader";

const Contact = () => {
	return (
		<div className="marketing-container flex relative justify-center bg-[#272DF1]">
			<div className="absolute block bottom-[-14px] rotate-[-1deg] w-[110vw] bg-[#272DF1] h-[50px]">
			</div>

			<div className="content-wrap">
				<div className="descr-wrap">
					<PurpleDotHeaderWhite title="START WORK WITH US"></PurpleDotHeaderWhite>
					
					<h6 className="main-title block text-5xl font-bold pt-8 text-white">
						This Is for the Marketing Ads
					</h6>

					<p className="paragraph-white mt-6">The website design should be user-friendly, easy to navigate, and aesthetically pleasing. It should be optimized for fast loading times, and the layout should be consistent across all pages.</p>

					<div className="btn-wrapper mt-16 smob1:mt-12">
						<Link className="cta-btn" to="/contact"> Start A Project</Link>
					</div>
				</div>

				<div className="img-wrap">
					<img className="object-cover w-full h-full" src="/Images/About/abouthero.png" alt="Contact Us"/>
				</div>
			</div>
		</div>
	);
}
export default Contact;