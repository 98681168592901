import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/All/Header'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'

import { Project } from '../../Components/Home'

import './Portfolio.css';

const Portfolio = () => {

    // const portfolio= [
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     },
    //     {
    //         name: 'name',
    //         desc: 'description'
    //     }
    // ]

    // project section
    
    const portfolio = [{
            id:"1",
            key:"1",
            name:"Corporate Website",
            category:"In-house Projects",
        },{
            id:"2",
            key:"2",
            name:"Election Monitoring",
            category:"WebApp Development",
        },{
            id:"3",
            key:"3",
            name:"Ecommerce",
            category:"WebApp Development",
        },{
            id:"4",
            key:"4",
            name:"FIS BURSA EUAT",
            category:"Support & Maintenance",
        },{
            id:"5",
            key:"5",
            name:"JR-Stich SDN BHD",
            category:"Landing Page",
        },{
            id:"6",
            key:"6",
            name:"Cloud Infrastructure",
            category:"Support and Maintenance",
    }];

    const [isHovering, setIsHovering] = useState({});


    // defining project component states
    if(Object.keys(isHovering).length < 1){
        let projectStates = {...isHovering};
    
        for(let i=0; i < portfolio.length; i++){
            console.log(portfolio[i].id);
            projectStates[portfolio[i].id] = false;
        }

        setIsHovering({...projectStates});
    }

    // handle mouse-in
    const handleMouseOver = (id) => {
        console.log('on mouseover id:', id)

        let copyObject = {...isHovering};

        copyObject[id] = true;

        setIsHovering({...copyObject});
    };

    // handle mouse-out
    const handleMouseOut = () => {
        console.log('onmouseout...')
        let copyObject = {...isHovering};

        Object.keys(copyObject).forEach(v => copyObject[v] = false)

        setIsHovering({...copyObject});
    };

    const [currentPage, setCurrentPage] = useState(1)
    const recordsPerPage = 8
    const lastIndex = currentPage * recordsPerPage
    const firstIndex = lastIndex - recordsPerPage
    const records = portfolio.slice(firstIndex, lastIndex)
    const npage = Math.ceil(portfolio.length / recordsPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    return (
        <div className="projects-page pb-52 tab1:pb-20">
            <Header title="Projects"/>

            <div className='projects-container flex justify-center pb-28 pt-12' id="project">
                <div className='flex flex-wrap max-w-[1400px] w-full justify-left'>
                    {records.map((item) => (
                        <div className='cell-container'>
                            <Link to="/portfolio/a" className='flex rounded-xl border text-white hover:bg-purple-bg-1 transition-all duration-75 ease-in'>
                                <Project key={item.id} data={item} isHovering={isHovering} handleHover={() => handleMouseOver(item.id)} quitHover={ ()=> handleMouseOut() } />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pagination-container flex items-center justify-center w-full ">
                <div className="grid grid-flow-col grid-cols-auto gap-4">
                    <div>
                        <a href="#project" className="l-clbtn-purple" onClick={prePage}><AiOutlineLeft/></a>
                    </div>

                    {numbers.map((n, i) => (
                        <div className="" key={i}>
                            <a href="#project" className={n === currentPage ? "l-clbtn-purple active" : "l-clbtn-purple"} onClick={() => changeCPage(n)}>{n}</a>
                        </div>
                    ))}

                    <div>
                        <a href="#project" className="l-clbtn-purple" onClick={nextPage}><AiOutlineRight/></a>
                    </div>
                </div>
            </div>
        </div>
    );

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function changeCPage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default Portfolio;