const PurpleDotHeader = ({title}) => {
	return (
		<div className="subheader flex flex-row items-center gap-6 tab1:gap-1">
			<div className="pointer rounded-sm"></div>

			<span className="container-header-black">{title}</span>
		</div>
	);
}

const PurpleDotHeaderWhite = ({title}) => {
	return (
		<div className="subheader flex flex-row items-center gap-6 tab1:gap-1">
			<div className="pointer rounded-sm"></div>

			<span className="container-header-white">{title}</span>
		</div>
	);
}

const DoublePurpleDotHeader = ({title}) => {
	return (
		<div className="subheader flex flex-row items-center gap-6 tab1:gap-1">
			<div className="pointer rounded-sm"></div>

			<span className="container-header-black">{title}</span>

			<div className="pointer rounded-sm"></div>
		</div>
	);
}

export {PurpleDotHeader, PurpleDotHeaderWhite, DoublePurpleDotHeader};