import { useState } from "react";
import { BsArrowDown, BsArrowRight } from 'react-icons/bs'

const Question = ({item}) => {
	const [display, setDisplay] = useState(false)

	const baseHeaderClass = 'p-6 rounded-[16px] flex flex-row justify-between items-center hover:cursor-pointer'
	const headerClass = display ? 'faq-title bg-purple-bg-2 rounded-b-none ' + baseHeaderClass : 'faq-title bg-gray-bg-3 ' + baseHeaderClass

	const baseHeaderTextClass = 'faq-title font-bold tracking-wider text-xl'
	const headerTextClass = display ? 'faq-title text-white ' + baseHeaderTextClass : 'faq-title text-black ' + baseHeaderTextClass

	return (
		<div className="faq w-full py-3 smob1:text-base">
			<div
				onClick={() => setDisplay(!display)}
				className={headerClass}
			>
				<span className={headerTextClass}>{item.question}</span>

				{display ? <BsArrowDown size="20" color="#fff"/> : <BsArrowRight size="20" color="#5B62FF"/>}
			</div>

			{display
			? <div className="bg-gray-bg-3 text-lgray1 text-xl px-11 py-5 leading-relaxed rounded-b-[16px]">
				{item.answer}
			</div>
			: ''}
		</div>
	);
}
export default Question;