import { Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { HiPhone } from "react-icons/hi"
import { PurpleDotHeader, DoublePurpleDotHeader } from "../All/PurpleDotHeader";

import './about.css';

const Hero = () => {
	return (
		<div className="hero section flex justify-center pb-32">
			<div className="container flex flex-row gap-20 max-w-[1400px] p-6 sdesk1:flex-col mob1:!gap-9 tab1:gap-14">
				<div className="image w-1/2 sdesk1:w-full tab1:aspect-square sdesk1:aspect-video sdesk1:h-auto h-screen rounded-[80px] overflow-hidden">
					<img src="/Assets/images/image-placeholder.png" alt="skele" className="h-full w-full object-fill"/>
				</div>

				<div className="detail w-1/2 sdesk1:w-full">
					<div className="header pb-12 mob1:!pb-6">
						<PurpleDotHeader title="WELCOME TO OUR COMPANY"/>

						<div><span className="header-black">We Are Increasing Business Success With Technology</span></div>

						<div><p className="paragraph-gray">The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.</p></div>
					</div>

					<div className="card w-full px-14 py-[44px] bg-[#f9f9f9] rounded-[30px] mob1:!px-8 mob1:!py-7 tab1:px-10 tab1:py-12">
						<div className="strongpoint flex flex-row gap-10 pb-12 mob1:!pb-6 mob1:!gap-4">
							<div className="bg-[#f1f1f1] rounded-full w-fit h-fit p-6 flex justify-center items-center text-purple text-4xl mob1:!text-2xl mob1:!p-5"><BsCheckLg/></div>

							<div>
								<span className="title">The best agency 2023</span>

								<p className="paragraph-gray">The website design should be user-friendly, easy to navigate, and aesthetically pleasing.</p>
							</div>
						</div>

						<div className="cta-wrap buttons flex flex-wrap">
							<div className="cta-btn">
								<Link to="/contact">
									Learn More
								</Link>
							</div>

							<div className="callus-btn flex flex-row items-center">
								<div className="m-clbtn-purple"><HiPhone/></div>

								<div className="descr flex flex-col">
									<a href="tel:601124413110" className="font-medium text-lgray1 text-base mob1:!text-xs">Call Us 24/7</a>

									<span className="font-medium text-black text-xl mob1:!text-base">(+60) 11 2441 3110</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const Testimonial = () => {
	const pp = '/Images/About/abouthero.png'
	const person = 'Images/About/person.png'

	return (
		<div className="clientTalk-container relative flex justify-center my-44">
			<div className="absolute h-full w-full top-0 bg-[rgba(222,222,222,0.2)]">
				<div>
					<img src={person} alt="Nik Aqim (CTO at MXI Solutions)" className="absolute h-[45%] bottom-0 left-[7%] sdesk1:hidden"/>
				</div>

				<div className="block absolute left-[10%] top-[5%] h-[123px] tab1:h-[80px] w-[123px] tab1:w-[80px] rounded-full overflow-hidden bg-[#C7F2F9] tab1:bg-[#CB00DD] shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]"></div>

				<div className="block tab1:hidden absolute right-[11%] bottom-[10%] h-[60px] w-[60px] rounded-full overflow-hidden bg-[#CB00DD] shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]"></div>

				<div className="block tab1:hidden absolute right-[6%] top-[11%] h-[89px] w-[89px] rounded-full overflow-hidden shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					<img src={pp} alt="" className="object-fill"/>
				</div>

				<div className="block tab1:hidden absolute top-[30%] right-[18%] sdesk1:right-[7%] h-[123px] sdesk1:h-[99px] w-[123px] sdesk1:w-[99px] rounded-full overflow-hidden shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					<img src={pp} alt="" className="object-fill"/>
				</div>
			</div>

			<div className="w-1/2 sdesk1:w-full sdesk1:px-9 tab1:px-4 relative text-center flex flex-col items-center pt-40 pb-28">
				<DoublePurpleDotHeader title="CLIENTS TALK"/>

				<div><span className="header-black">People talk about us</span></div>

				<div className="block h-48 w-48 rounded-full overflow-hidden mb-10 mt-20 shadow-[0px_1px_100px_1px_rgba(0,0,0,0.3)]">
					<img src={pp} alt='Nik Aqim (CTO at MXI Solutions)' className="object-contain"/>
				</div>

				<div className="block pb-14">
					<span className="text-5xl text-black font-semibold pb-8 block mob1:!text-3xl tab1:text-4xl">"Elite partnership work"</span>

					<p className="paragraph-gray">The website design should be user-friendly, easy to navigate, and aesthetically pleasing.The website design should be user-friendly, easy to navigate.</p>
				</div>

				<div>
					<div><span className="text-3xl text-black font-medium tracking-wider pb-2 block mob1:!text-xl mob1:!pb-0 tab1:pb-1 tab1:text-2xl">Nik Aqim</span></div>

					<div><span className="text-2xl text-gray font-medium tracking-wider mob1:!text-lg tab1:text-xl">CTO at <span className="text-purple">(MXI Solutions)</span></span></div>
				</div>
			</div>
		</div>
	);
}

export { Hero, Testimonial };