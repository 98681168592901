import './navbar.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// component
import MenuList from '../../Components/Navbar/MenuList';
import TopMenu from '../../Components/Navbar/TopMenu';
import Menu from '../../Components/Navbar/Menu';

function Navigation() {
  //Calling t and i18n method from useTranslation hook
  const { t, i18n } = useTranslation();

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  };

  const [dispNavbar, setDispNavbar] = useState(false);
  
  const handleMenu = (dispNavbar) => {
    setDispNavbar(!dispNavbar);
  };

  const hideMenu = () => {
    console.log('hidemenu triggered')
    setDispNavbar(false)
  }

  console.log(dispNavbar);

  return (
    <div className="relative">
      <TopMenu></TopMenu>

      <Menu handleMenu={handleMenu} dispNavbar={dispNavbar}></Menu>

      <div
        className={dispNavbar ? 'menubar-container block absolute bg-white/10 w-full' : 'menubar-container hidden'}
      >
        <MenuList hideMenu={hideMenu}></MenuList>
      </div>
    </div>
  );
}

export default Navigation;
